import React from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import LayoutTemplate from './pages';
import { FaqSection } from './pages/helpcentre';
import { HomeTemplate } from './pages/home';
import { PrivacyPolicy } from './pages/privacy-policy';

export const Application: React.FC<any> = () => {

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path={''} element={<LayoutTemplate />}>
            <Route path='' element={<Navigate to={'/home'} replace />} />
            <Route path={'/home'} element={<Outlet />}>
              <Route path={'/home'} element={<HomeTemplate />} />
            </Route>
          </Route>
              <Route path={'/faq'} element={<FaqSection faqs={[]}/>} />
              <Route path={'/privacy-policy'} element={<PrivacyPolicy />} />
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default Application;
