/* This example requires Tailwind CSS v3.0+ */
import { useState } from 'react'
import { Dialog, Transition } from '@headlessui/react';
import { NavigationModel } from "../shared/layout/header/model"
import { IconPath } from '../../common/icon';
import React from 'react'
import bgImage from '../../assets/bgimagehome.png';

export interface HomeProps {
}

export const OurPhilosophy: React.FC<HomeProps> = (props: HomeProps) => {

    return (
        <></>
        // <div id='philosophy' data-name='philosphy' className='xl:w-[1200px] mx-auto  w-full h-[700px] align-center text-white'
        // style={{backgroundImage: "url('../../src/assets/bgimagehome.png')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover"}}>
        //     <div className="relative px-6 lg:px-8 text-center text-[#3AB100] bg-[#d4e2d4] h-60vh flex items-center h-full">
        //         <div className="mx-auto py-8 sm:py-4 lg:py-4">
        //         <div className="text-center w-full">
        //             <h1 className="text-4xl font-bold tracking-tight  sm:text-6xl">
        //             Brand Philosophy
        //             </h1>
        //             <p className="lg:w-[600px] mt-6 text-xl leading-8">
        //             Most of us struggle to save but we are darn good at spending! 
        //             We usually struggle to save because we don’t have a tool that embraces our needs. 
        //             Our focus is to help our Guac users micro-save on all qualified purchases to fund their goals. 
        //             Saving a % of each transaction keeps the Guac user in control of exactly what they save & after just a few days the savings add up. 
        //             Now that vacation, new car, watch or designer bag is easier to attain than ever before.
        //             </p>
        //         </div>
        //         </div>
        //     </div>
        // </div>

     


    )
}