import { useState } from "react";
import React from "react";
import { NavigationModel } from "../../pages/shared/layout/header/model";
import { Link, animateScroll as scroll, } from 'react-scroll'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'


const NavMenu = () => {
    const [nav, setNav] = useState(false)
    const handleClick = () => setNav(!nav)

    const handleClose =()=> setNav(!nav)

    return (
        <div className='fixed top-0 left-1/2 w-full transform -translate-x-1/2 bg-white mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 z-50'>
          <div className="lg:px-8 mt-6 flex relative justify-between items-center  h-full">
            <div className="left xsm:px-2 md:px-2">
                {/* <h1 className="text-3xl font-bold mr-4 sm:text-4xl">Logo.</h1> */}
                <img src={require('../../assets/Guac_Horizontal_Logo.png')} alt="" />
            </div>
            <div id="divmesi" className="flex items-center w-3/5 mx-auto justify-between">
                <ul className=" flex mx-auto w-full justify-evenly hidden md:flex text-sm cursor-pointer">
                <li>
                    <Link to="home" smooth={true} duration={500} className="px-2 py-1 hover:text-[#3AB100] block">How it works</Link>
                </li>
                <li>
                    <Link to="goal" smooth={true} duration={500} className="px-2 py-1 hover:text-[#3AB100] block">Goals</Link>
                </li>
                <li>
                    <Link to="marketplace" smooth={true} duration={500} className="px-2 py-1 hover:text-[#3AB100] block">Marketplace</Link>
                </li>
                {/* <li>
                    <Link to="pricing" smooth={true} duration={500} className="px-2 py-1 hover:text-[#3AB100] block">Pricing</Link>
                </li> */}
                {/* <li>
                    <Link to="philosophy" smooth={true} duration={500} className="px-2 py-1 hover:text-[#3AB100] block">Our Philosophy</Link>
                </li> */}
                <li>
                    <a href="/faq" className="px-2 py-1 hover:text-[#3AB100] block">Help Center - FAQ</a>
                </li>
                </ul>
                
            </div>
            <div className="hidden md:flex pr-4 items-center">
                <a href="https://app.guacapp.com/login"><button  className="border-none text-sm bg-transparent text-black mr-4">Log In</button></a>
                <a href="https://app.guacapp.com/create-account"><button className="bg-[#3AB100] text-sm px-4 py-2 rounded-md text-white">Try for free</button></a>
                </div>
            <div className="md:hidden mr-4" onClick={handleClick}>
                {!nav ? <Bars3Icon className="w-5" /> : <XMarkIcon className="w-5" />}
            </div>
            </div>
    
          <ul className={!nav ? 'hidden' : 'absolute bg-white text-sm w-full px-8 z-10'}>
              <li className='w-full mt-6 mb-2'><Link to="home" smooth={true} duration={500} onClick={handleClose} > How it works</Link></li>
              <li className='w-full mb-2'><Link to="goal" smooth={true} duration={500} onClick={handleClose} >Goals</Link></li>
              <li className='w-full mb-2'><Link to="marketplace" smooth={true} duration={500} onClick={handleClose} >Marketplace</Link></li>
              <li className='w-full mb-2'> <a href="/faq">Help Center - FAQ </a></li> 
              {/* <li className='w-full mb-2'><Link onClick={handleClose} to="pricing"  >Pricing</Link></li> */}
    
            <div className='flex flex-col my-4'>
                <a href="https://app.guacapp.com/create-account"><button className='w-full px-4 py-4 bg-[#3AB100] text-white'>Try for free</button></a>
                <button className='bg-transparent text-[#3AB100] px-8 py-3 mb-4'> <a href="https://app.guacapp.com/login"> <p className="text-gray-800 inline-block">Do you have an account? </p> </a><a href="https://app.guacapp.com/login">Log In</a></button>
            </div>
          </ul>

        </div>
      );
    };

export default NavMenu