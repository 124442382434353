import { useState } from 'react'
import { Dialog, Transition } from '@headlessui/react';
import { NavigationModel } from "../shared/layout/header/model"
import { IconPath } from '../../common/icon';
export interface HomeProps {
}

export const SupportTemplate: React.FC<HomeProps> = (props: HomeProps) => {

    return (
        <>
        <div className="xl:max-w-7xl mx-auto  flex flex-col lg:flex-row relative px-6 py-32 xsm:py-8 sm:py-12 lg:py-24 border-b">
            <div className="div1 w-full md:ml-2 lg:w-2/3 mx-auto max-w-2xl">
                <div className="mb-8 flex justify-left">
                <img className='max-w-[50px] lg:w-[100px] text-left' src={require('../../assets/Guac_Horizontal_Logo.png')} />
                </div>
                <div className=" w-4/5 text-left">
                    {/* <p className="mt-6 text-sm leading-8 text-gray-900">
                    Making the world a better place through constructing elegant hierarchies.
                    </p> */}
                    <ul className="flex mt-6">
                        <li className="max-w-[50px] mr-4">
                            <a href="https://www.instagram.com/guacapp.savings/">
                            <img src={require('../../assets/instagram.svg').default} alt="" />
                            </a>
                        
                        </li>
                        <li className="max-w-[50px] mr-4">
                            <a href="https://www.linkedin.com/in/guac-save-while-you-spend-5180b91aa/">
                            <img src={require('../../assets/linkedin.svg').default} alt="" />
                            </a>
                        
                        </li>
                        <li className="max-w-[50px] mr-4">
                            <a href="https://www.facebook.com/guacapp.savings">
                            <img src={require('../../assets/facebook.svg').default} alt="" />
                            </a>
                        
                        </li>
                        <li className="max-w-[50px] mr-4">
                            <a href="https://twitter.com/guacappsavings">
                            <img src={require('../../assets/twitter.svg').default} alt="" />
                            </a>
                        
                        </li>
                    </ul>
                </div>
            </div>

            {/* <div className="div2 w-full lg:w-1/3 md:ml-2 mx-auto max-w-2xl text-left">
                <p className=" text-md leading-8 text-gray-600 font-bold xsm:mt-10 lg:mt-0">
                Contact Info
                </p>
                <ul className="mt-6 text-sm">
                <li className="mb-4">
                    33831 Granada Drive, Dana Point, CA 92629
                </li>
                <li className="mb-4 flex items-center">
                    <img src={require('../../assets/footerphone.svg').default} alt="" className="mr-4 max-w-[30px]" />+1 (949) 992-5707
                </li>
                <li className="mb-4 flex items-center">
                    <img src={require('../../assets/footermail.svg').default} alt="" className="mr-4 max-w-[30px]" />Support@guacapp.com
                </li>
                </ul>
            </div> */}

            
        </div>
            <div className="div3 mx-auto max-w-2xl text-center mt-5">
            <div className="flex flex-col lg:flex-row justify-center lg:justify-evenly items-center">
                <p className="mt-6 lg:mt-0 text-sm leading-8 text-gray-600">
                    <a href="/privacy-policy">Privacy Policy </a>
                </p>
                <p className="mt-6 lg:mt-0 text-sm leading-8 text-gray-600">
                    <a href="/faq"> Support</a>
                </p>
                <p className="mt-6 lg:mt-0 text-sm leading-8 text-gray-600">
                    <a href="/faq">FAQ </a> 
                </p>
            </div>
            <p className="mt-6 text-sm leading-8 text-gray-600">
                 All deposits are insured by the Federal Deposit Insurance Corporation (FDIC) up to $250,000 through nbkc bank, Member FDIC.
            </p>
            <p className="mt-6 text-sm leading-8 text-gray-600">
                © 2023 New World Savings, Inc. dba Guac All Rights Reserved.
            </p>
        </div>

        </>
    )
}