import { useState } from "react";

import 'swiper/css/navigation';
import 'swiper/css/scrollbar';

import React, { useRef } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";


// import required modules
import { Pagination, Scrollbar, A11y, Navigation } from "swiper";

const MarketPlace = () => {


  return (
      <>

    <div className="bg-white">

    <div id="marketplace" className="flex max-w-7xl  mx-auto  xl:flex-row relative xsm:px-4 md:pl-6 lg:pl-20 lg:pr-0 bg-primary xsm:flex-col lg:flex-row md:w-full">
                <div className="lg:w-1/2 xsm:my-0 lg:my-40 xsm:w-full">
                    <div className="hidden sm:mb-8 sm:flex sm:justify-center"></div>
                    <div className=" lg:w-full flex-col justify-center items-center text-white xsm:w-full">
                    <h1 className="text-left text-4xl md:font-bold tracking-tight xsm:mt-6 xsm:text-xl lg:text-5xl">
                        Save & Earn across all devices
                    </h1>
                    <p className="text-left mt-6 lg:text-lg xsm:text-sm leading-8">
                    With both mobile and web apps, Guac gives you unlimited access to Savings Tools and the Marketplace at any time and from any place.
                    </p>

                    <div className="xl:w-1/2 lg:hidden lg:my-0 xsm:w-full md:my-0">
                        <img className='mx-auto'
                        src={require('../../assets/goalsimg.png')}
                        alt=""
                        />
                    </div>


                    <div className='flex pt-12 left relative text-primary md:flex-col'>
                        <ul className='flex w-full md:justify-items-start xsm:justify-evenly  xsm:flex-col md:flex-row'>
                                <li className='bg-white px-4 py-1 rounded-md flex-row xsm:mb-3 md:mr-4'>
                                    <a className = 'h-[40px] flex items-center text-sm xsm:justify-center md:justify-items-start' href="">
                                    <img className='w-[40px]' src={require('../../assets/apple.svg').default} alt="" />
                                    <div className='pl-1'>
                                        <label className='text-[11px]' htmlFor="appstore">GET IT ON</label>
                                        <a href="https://apps.apple.com/us/app/guac-save-while-you-spend/id1463903144"><h3 className='font-bold'>App Store</h3></a>
                                    </div>
                                    </a>
                                </li>
                                <li className='bg-white px-4 py-1 rounded-md flex-row xsm:mb-3 lg:flex-col md:mr-4' >
                                    <a className = 'h-[40px] flex items-center xsm:justify-center md:justify-items-start text-sm' href="">
                                    <img className='w-[40px]' src={require('../../assets/googleplay.svg').default} alt="" />
                                    <div className='pl-1'>
                                        <label className='text-[11px]' htmlFor="appstore">GET IT ON</label>
                                        <a href="https://play.google.com/store/apps/details?id=com.guac&pli=1"><h3 className='font-bold'>Google Play</h3></a>
                                    </div>
                                    </a>
                                </li>

                                <li className='bg-white px-4 py-1 rounded-md flex-row xsm:mb-3 lg:flex-col '>
                                    <a className = 'h-[40px] flex items-center xsm:justify-center md:justify-items-start text-sm' href="">
                                    <img className='w-[40px]' src={require('../../assets/desktop.svg').default} alt="" />
                                    <div className='pl-1'>
                                        <label className='text-[11px]' htmlFor="appstore">Access</label>
                                        <a href="https://app.guacapp.com/"><h3 className='font-bold'>Web App</h3></a>
                                    </div>
                                    </a>
                                </li>

                            </ul>
                        </div>
                    
                    </div>
                </div>
                <div className="xl:w-1/2 lg:flex xsm:hidden lg:my-0 xsm:w-full md:my-0">
                    <img className='mx-auto'
                    src={require('../../assets/goalsimg.png')}
                    alt=""
                    />
                </div>
                
            </div>


        <div className="max-w-7xl  mx-auto  flex flex-col text-center pt-32">
            <div className="text-center">
                <h1 className=" text-4xl font-bold lg:mb-8 tracking-tight text-gray-900 sm:text-5xl">
                    Get paid on every purchase  
                </h1>
            </div>
            <div className="w-full text-center">
                <img className="mx-auto" src={require('../../assets/marketplaceimg.png')} alt="" />
            </div>

            <div className="flex md:flex-row xsm:flex-col bg-[#f1f1f1] py-14 md:px-2 lg:px-12">
                <div className='flex px-6 pl-12 left xms:w-full md:w-1/3 relative items-center ' >
                    <div>
                    <img className='lg:w-[50px] max-w-[50px] w-full' src={require('../../assets/marketplacegift.svg').default} alt="" />
                    </div>
                    <div className='flex text-left flex-col px-6  text-black'>
                        <h3 className=' text-l font-bold'>
                        Shop & Earn Cashback with all of your favorite brands
                        </h3>
                        <p className="text-sm">
                        {/* A high-quality solution beautifully designed for startups */}

                        </p>
                    </div>
                </div>

                <div className='flex px-6 pl-12 left xms:w-full md:w-1/3  relative items-center' >
                    <div>
                    <img className='lg:w-[50px] max-w-[50px] w-full' src={require('../../assets/marketplacehome.svg').default} alt="" />
                    </div>
                    <div className='flex text-left flex-col px-6  text-black'>
                        <h3 className=' text-l font-bold'>
                        Smooth one-click access via the Marketplace 
                        </h3>
                        <p className="text-sm">
                        {/* A high-quality solution beautifully designed for startups */}
                        </p>
                    </div>
                </div>

                <div className='flex px-6 pl-12 left xms:w-full md:w-1/3  relative items-center' >
                <div>
                    <img className='lg:w-[50px] max-w-[50px] w-full' src={require('../../assets/marketplacepig.svg').default} alt="" />
                </div>
                    <div className='flex text-left flex-col px-6  text-black'>
                        <h3 className=' text-l font-bold'>
                        Cashback seamlessly delivered to boost your savings goals
                        </h3>
                        <p className="text-sm">
                        {/* A high-quality solution beautifully designed for startups */}
                        </p>
                    </div>

                </div>


            </div>

        </div>

         <div className=" max-w-7xl mx-auto  flex flex-col bg-primary600 py-16">
            <div className="mx-auto py-8 sm:py-4 lg:py-4">
                    <div className="text-center w-full mb-8 text-white">
                        <h1 className="text-md tracking-tight sm:text-md">
                        Testimonials
                        </h1>
                        <p className="lg:w-[800px] xsm:text-lg mt-2 font-bold lg:text-4xl md:text-xl">
                        Our users are saving money like never before, see what they have to say...
                        </p>
                    </div>
                </div>

            <Swiper
                    style={{ overflow: "hidden",  }}
                    slidesPerView={3}
                    spaceBetween={30}
                    
                    scrollbar={{
                        hide: true,
                    }}
                    breakpoints={{
                        300: {
                            slidesPerView: 1,
                        },
                        768:{
                            slidesPerView: 3,
                        }
                    }}
                    modules={[Scrollbar]}
                    className="w-full px-4 flex "
                >

                <div className="w-full mx-auto">
                <SwiperSlide className="h-[350px] xsm:w-[300px]!important md:w-[500px]!important lg:w-[700px]!important flex flex-row lg:flex-row sm:flex-col  bg-white rounded-xl">
                   
                    {/* <SwiperSlide className="h-[350px] xsm:w-[300px]!important md:w-[500px]!important lg:w-[700px]!important flex lg:flex-row sm:flex-col  bg-white rounded-xl"> */}
                        <div className="flex px-8 py-8 flex-col rounded-md justify-between bg-background-testimonials  bg-no-repeat bg-[length:200px_100px]">
                            <p className="w-[240px]">
                            Better than Acorns! 
                            My Accountant told me about this app to help me save money & it works like a charm! I like that this app lets me pick the % to save each time I make a purchase and lets me have immediate access to the money vs and IRA like Acorns. I also love the cash back when shopping!
                            </p>
                            <p className="font-bold flex justify-items-start w-full">
                            S Jen 
                            </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="h-[350px] xsm:w-[300px]!important md:w-[500px]!important lg:w-[700px]!important flex flex-row lg:flex-row sm:flex-col  bg-white rounded-xl">
                   
                   {/* <SwiperSlide className="h-[350px] xsm:w-[300px]!important md:w-[500px]!important lg:w-[700px]!important flex lg:flex-row sm:flex-col  bg-white rounded-xl"> */}
                       <div className="flex px-8 py-8 flex-col rounded-md justify-between bg-background-testimonials  bg-no-repeat bg-[length:200px_100px]">
                           <p className="w-[240px]">
                           
                            These guys are the best!!!
                            I have never been a saver before but now thanks to this wonderful app I am able to and not even notice is missing while doing it. Their support team is bomb!!! So patient and willing to help with anything and everything.
                           </p>
                           <p className="font-bold flex justify-items-start w-full">
                           Vegas M
                           </p>
                       </div>
                   </SwiperSlide>
                   <SwiperSlide className="h-[350px] xsm:w-[300px]!important md:w-[500px]!important lg:w-[700px]!important flex flex-row lg:flex-row sm:flex-col  bg-white rounded-xl">
                   
                   {/* <SwiperSlide className="h-[350px] xsm:w-[300px]!important md:w-[500px]!important lg:w-[700px]!important flex lg:flex-row sm:flex-col  bg-white rounded-xl"> */}
                       <div className="flex px-8 py-8 flex-col rounded-md justify-between bg-background-testimonials  bg-no-repeat bg-[length:200px_100px]">
                           <p className="w-[240px]">
                            Payed off my credit card 
                            I have been using this app for 6 months and have been able to payoff my 2k credit card debit. The you tip yourself on each purchase you make, make it effortless. Best app to quickly payoff your debt. Highly recommend!
                           </p>
                           <p className="font-bold flex justify-items-start w-full">
                           Greg A 
                           </p>
                       </div>
                   </SwiperSlide>
                   
                   <SwiperSlide className="h-[350px] xsm:w-[300px]!important md:w-[500px]!important lg:w-[700px]!important flex flex-row lg:flex-row sm:flex-col  bg-white rounded-xl">
                   
                        <div className="flex px-8 py-8 flex-col rounded-md justify-between bg-background-testimonials  bg-no-repeat bg-[length:200px_100px]">
                           <p className="w-[240px]">
                            Best Savings App
                            I have tried various savings apps over the years and never stuck with them. Being able to set a tip to myself in an account that is different from my bank has helped me be able to save money without paying attention to it. The money adds up quickly.
                            </p>
                           <p className="font-bold flex justify-items-start w-full">
                           Dr. Sampson 
                           </p>
                       </div>
                   </SwiperSlide>

                   <SwiperSlide className="h-[350px] xsm:w-[300px]!important md:w-[500px]!important lg:w-[700px]!important flex flex-row lg:flex-row sm:flex-col  bg-white rounded-xl">
                   
                        <div className="flex px-8 py-8 flex-col rounded-md justify-between bg-background-testimonials  bg-no-repeat bg-[length:200px_100px]">
                           <p className="w-[240px]">
                           Tipping myself on my credit card purchases 
                            Guac’s Auto-Tip let’s me tip myself based on my credit card spending. I’m able to achieve my goals quickly and then they pay me to use my savings with any of their partners!
                            </p>
                           <p className="font-bold flex justify-items-start w-full">
                           AH 
                           </p>
                       </div>
                   </SwiperSlide>

                   <SwiperSlide className="h-[350px] xsm:w-[300px]!important md:w-[500px]!important lg:w-[700px]!important flex flex-row lg:flex-row sm:flex-col  bg-white rounded-xl">
                   
                        <div className="flex px-8 py-8 flex-col rounded-md justify-between bg-background-testimonials  bg-no-repeat bg-[length:200px_100px]">
                           <p className="w-[240px]">
                           So far with this app I find this better than other saving apps I have used. Other apps would just take money and then when you spend you find yourself in a lot less money. I didn't find benefit in that. I like the options where you save a portion of what you spend. I feel thats more manageable than grabbing chunks of money and putting it into your account for no reason.
                            </p>
                           <p className="font-bold flex justify-items-start w-full">
                           Ashley Ruano
                           </p>
                       </div>
                   </SwiperSlide>


                   <SwiperSlide className="h-[350px] xsm:w-[300px]!important md:w-[500px]!important lg:w-[700px]!important flex flex-row lg:flex-row sm:flex-col  bg-white rounded-xl">
                   
                        <div className="flex px-8 py-8 flex-col rounded-md justify-between bg-background-testimonials  bg-no-repeat bg-[length:200px_100px]">
                           <p className="w-[240px]">
                           
                            REAL PEOPLE over the phone! An actual number to talk to a human! Excellent customer service, very polite and answered all my questions.
                            </p>
                           <p className="font-bold flex justify-items-start w-full">
                           Ben Margelony 
                           </p>
                       </div>
                   </SwiperSlide>


                   <SwiperSlide className="h-[350px] xsm:w-[300px]!important md:w-[500px]!important lg:w-[700px]!important flex flex-row lg:flex-row sm:flex-col  bg-white rounded-xl">
                   
                        <div className="flex px-8 py-8 flex-col rounded-md justify-between bg-background-testimonials  bg-no-repeat bg-[length:200px_100px]">
                           <p className="w-[240px]">
                            Really cool way to save. Its basically like a subscription to your savings that you forget about but it adds up and you save a lot really quickly without realizing
                            </p>
                           <p className="font-bold flex justify-items-start w-full">
                           Rod Sweis
                           </p>
                       </div>
                   </SwiperSlide>

                   <SwiperSlide className="h-[350px] xsm:w-[300px]!important md:w-[500px]!important lg:w-[700px]!important flex flex-row lg:flex-row sm:flex-col  bg-white rounded-xl">
                   
                        <div className="flex px-8 py-8 flex-col rounded-md justify-between bg-background-testimonials  bg-no-repeat bg-[length:200px_100px]">
                           <p className="w-[240px]">
                            Great app concept! Really helps you focus on saving without completely trying to restrict you from spending at all. Cool tool for anyone to use and save.
                            </p>
                           <p className="font-bold flex justify-items-start w-full">
                           Adnan Kharuf
                           </p>
                       </div>
                   </SwiperSlide>
                    
                    </div>
                    
            </Swiper>
        </div> 
    </div>


    <div className='max-w-7xl  mx-auto relative px-6 lg:px-8 xsm:px-0 text-center bg-primary h-60vh flex  items-center h-full py-12 mb-8 lg:flex-col md:flex-col xsm:w-full xsm:flex-col'>
        <div className='flex xsm:px-4 md:px-6 md:items-start xsm:items-center lg:items-center xsm:flex-col lg:flex-row lg:pl-8 left w-1/2 relative lg:w-full xsm:w-full' >
            <div className="bg-white md:rounded-3xl md:ml-6 md:mb-2 lg:ml-0 md:items-baseline xsm:rounded-xl lg:px-4 lg:py-4 xsm:p-2 xsm:items-center">
                <img className='xsm:px-1 md:px-4 max-w-[60px] lg:px-0' src={require('../../assets/Guac_Symbol_Logo.svg').default}  alt="" />
            </div>
            <div className='flex md:text-left xsm:text-center flex-col md:px-6 xsm:px-1 text-white'>
                <h3 className=' text-2xl font-bold'>
                    Download the Guac App
                </h3>
                <p className="text-sm">
                Get a one-month Free Trial by Downloading the Guac App now. Start saving today!
                </p>
            </div>
        </div>

        <div className='flex pt-12 left md:px-8 relative text-primary xsm:px-2 w-full lg:px-6 md:flex-col'>
            <ul className='flex xsm:flex-col md:flex-row xsm:w-full   md:w-'>
                    <li className='bg-white px-6 py-1 rounded-md xsm:flex-col md:flex-row md:mr-4 xsm:mb-3'>
                        <a className = 'h-[40px] flex items-center xsm:justify-center text-sm' href="https://apps.apple.com/us/app/guac-save-while-you-spend/id1463903144">
                        <img className='w-[40px]' src={require('../../assets/apple.svg').default} alt="" />
                        <div className='pl-1'>
                            <label className='text-[11px]' htmlFor="appstore">GET IT ON</label>
                            <h3 className='font-bold'>App Store</h3>
                        </div>
                        </a>
                    </li>
                    <li className='bg-white px-6 py-1 rounded-md xsm:flex-col md:flex-row md:mr-4 xsm:mb-3' >
                        <a className = 'h-[40px] flex items-center xsm:justify-center text-sm' href="https://play.google.com/store/apps/details?id=com.guac&pli=1">
                        <img className='w-[40px]' src={require('../../assets/googleplay.svg').default} alt="" />
                        <div className='pl-1'>
                            <label className='text-[11px]' htmlFor="appstore">GET IT ON</label>
                            <h3 className='font-bold'>Google Play</h3>
                        </div>
                        </a>
                    </li>

                    <li className='bg-white px-6 py-1 rounded-md xsm:flex-col md:flex-row md:mr-4 xsm:mb-3'>
                        <a className = 'h-[40px] flex items-center xsm:justify-center text-sm' href="https://app.guacapp.com/">
                        <img className='w-[40px]' src={require('../../assets/desktop.svg').default} alt="" />
                        <div className='pl-1'>
                            <label className='text-[11px]' htmlFor="appstore">Access</label>
                            <h3 className='font-bold'>Web App</h3>
                        </div>
                        </a>
                    </li>

                </ul>
            </div>


    </div>

    </>
  );
};

export default MarketPlace;