import React from 'react';
import { BrowserRouter, Outlet, Route, Routes, useNavigate } from "react-router-dom";
import NavMenu from '../common/routes/NavMenu';
import Calculator from './calculator';
import { GoalsTemplate } from './goals';
import { FaqSection } from './helpcentre';
import { FAQComponent } from './helpcentre/faq';
import { HomeTemplate } from './home';
import  MarketPlace  from './marketplace';
import { OurPhilosophy } from './philosophy';
import Pricing from './pricing';
// import { NavigationMenus } from '../common/routes/NavigationMenu';
import { HeaderTemplate } from './shared/layout/header';
import { NavigationModel } from './shared/layout/header/model';
import { SupportTemplate } from './support';

export const LayoutTemplate: React.FC =() => {
  //const navMenu = navigationMenus();
  // console.log(`LayoutTemplate for :`,navigationMenus )

  const navigate = useNavigate();
  // const navMenu: NavigationModel[] = navigationMenus();

  const handleNavigation = (path: string) => {
    navigate(path);
  }

  return (
    <div className="isolate bg-white w-full">
      {/* <HeaderTemplate navigation={navMenu} onNavigation={handleNavigation}></HeaderTemplate> */}
      {/* <navigationMenus /> */}
    <NavMenu />
    <HomeTemplate />
    <GoalsTemplate />
 
    <OurPhilosophy />
    <Calculator />
    <Pricing />
    <MarketPlace />
    <SupportTemplate />



      {/* <main>
        <Outlet />
      </main> */}

      </div>
  );
}

export default LayoutTemplate;
