/* This example requires Tailwind CSS v3.0+ */
import { useState } from 'react'
import { Dialog, Transition } from '@headlessui/react';
import { NavigationModel } from "../shared/layout/header/model"
import { IconPath } from '../../common/icon';
import React from 'react'
import bgImage from '../../assets/bgimagehome.png';

export interface HomeProps {
}

export const PrivacyPolicy: React.FC<HomeProps> = (props: HomeProps) => {

    return (
        
        <div  className='xl:w-[1200px] mx-auto  w-full align-left text-white'>
            <div className="relative px-6 lg:px-8 text-left text-gray-900  h-60vh flex items-left h-full">
                <div className="mx-auto py-8 sm:py-4 lg:py-4">
                <div className="text-left w-full">
                    <h1 className="text-l font-bold tracking-tight  sm:text-xl">
                    Guac Privacy & Policy
                    </h1>
                    <p className=" mt-6 text-sm leading-8">
                    

                    Guac is committed to maintaining the confidentiality, integrity and security of any personal information about our users. This Privacy Policy explains how we protect personal and other information provided through our website www.guacapp.com  (the “Website”) and our mobile application (the “App”) and how we collect, use, and share that information in connection with our services offered through either of these (the “Service”).
                    “Personal information” for purposes of this Policy means information that identifies you, such as your name, address, social security number, driver’s license ID number, ID number, passport number, phone number, fax number or email address. Below, we discuss when and why we collect different types of personal information.
                    Guac implements its privacy and security standards to guard against identity theft and provide security for your personal information. We regularly review our privacy and security policies and adapt them as necessary to deal with new challenges.
                    Guac updates this Privacy Policy periodically. The date of last revision is 10/2/17. Changes take effect immediately
                    upon posting.
                    If you have questions, comments, concerns or feedback regarding this Privacy Policy or any other privacy or security
                    concern, send an email to support@Guacapp.com

                    <br/><strong>Your Privacy is Not for Sale</strong><br/>
                    We take your privacy seriously. We do not sell or rent your personal information to anyone, for any reason.

                    <br/><strong>You Can Delete Your Data Anytime by Deleting your Account</strong><br/>
                    You may request that we delete your account for the Service. If you do so, your Personal Information will be permanently expunged from our servers and further access to your account will not be possible. We will also promptly disconnect any connection we had established to your Account Information (see below) and delete all account credentials.
                    However, portions of your Personal Information, in de-identified and aggregate forms, may remain on our system indefinitely. We reserve the right to use this aggregate data to improve our service and for other lawful purposes, as discussed below.
                    Also, please note that we also may need to store your data as required by federal, state or local law, even if you
                    request that we delete it.
                    To request deletion of your account, email support@guacapp.com, requesting a deletion of your account. Merely deleting the app from your device does not constitute a request to delete your account.

                    <br/><strong>Your Security</strong><br/>
                    <br/><strong>We Are Committed To Keeping Your Personal Information Secure</strong><br/>
                    The security of your personal information is important to us. As discussed below, we utilize physical, technical, and organizational security measures to protect against loss, misuse, and alteration of information under our control. We offer industry–standard practices and security measures to safeguard and secure the personal information we collect.
                    No method of transmission over the Internet, or method of electronic storage, is 100% secure, however. Therefore, we cannot guarantee its absolute security.

                    We use a combination of firewall barriers, encryption techniques and authentication procedures, among others, to maintain the security of your online session and to protect Guac accounts and systems from unauthorized access. When you register for the Service, Guac requires a password from you for your privacy and security. Guac transmits
                    information such as your User Profile Information or Account information securely.

                    <br/><strong>Guac Ensures Secure Communications with Encryption</strong><br/>
                    All communications between your device and Guac are encrypted. This enables client and server applications to communicate in a way that is designed to prevent eavesdropping, tampering and message forgery.

                    <br/><strong>It is your responsibility to keep your Login ID, Password, Mobile Device, and Email Account Confidential</strong><br/>
                    We maintain strict rules to help prevent others from guessing your password. We also recommend that you change your password periodically. Your password must be at least 8 characters in length. You are responsible for maintaining the security of your Login ID and Password. You may not provide these credentials to any third party. If you believe that they have been stolen or been made known to others, you must contact us immediately at support@guacapp.com, but in any event you should change your password immediately via the Service.
                    You also agree that you control and limit access to the email account and mobile device. If your email address changes, you are responsible for informing us of that change.
                    We are not responsible if someone else accesses your account through Registration Information they have obtained from you or through a violation by you of this Privacy Policy or the Terms of Use.

                    <br/><strong>Collecting Your Personal Information</strong><br/>
                    Certain areas and features of our Website or Service are available to you without registration or the need to provide to us any personal information. However, other features of the Website or the Service may require registration, which involves provision of your name, email address, mobile phone number and a password.
                    When you use certain features of the Service, we collect a variety of other personal information from you. Although this information varies depending on how you use the Service, it may include such information as your name, address, phone number, email address, social security number, driver’s license number, state ID card number, passport number, utility bill information, and login information for online financial and other accounts. In some cases, we will collect scanned copies of such information.

                    <br/><strong>Keep Your Personal Information Up to Date to Gain Full Access</strong><br/>
                    It is your responsibility to keep your personal information up to date by updating your profile at www.guacapp.com. We are not responsible for costs or other issues arising from you not keeping your personal information up to date. In order to benefit from the full functionality of the Service, you must provide your third-party account log ins to allow Guac and our partners to access your account data at those financial institutions for your use. From time to time we may request other personal information to provide you with other benefits of the Service. In all such instances, you will be given the opportunity to provide or to decline to provide that information, and it will be used only for the stated purpose.
                    We may combine the Personal Information we collect from you with information we collect from other sources, both offline and online. This includes any information you provide to us while using our Service as well as when, where, and how you:

                    make payments, purchases and transfers or
                    your use of our website, mobile application or services or
                    any other services provided by our partners.
                    

                    Forward-to-a-Friend: We offer a feature that allows you to share a message with a friend encouraging him or her to use Guac. If you choose to use this feature, we will ask you for the recipient’s name and email address, along with the text of any message you choose to include, or you may be able to use a pre-populated message. By using this feature, you represent that you are entitled to use and provide us with the recipient’s name and email address for this purpose.

                    <br/><strong>We May Use Your Personal Information</strong><br/>
                    Guac and its affiliated companies use your personal information only as follows:

                    to analyze site usage and improve the Service;
                    to deliver to you any administrative notices, alerts and communications relevant to your use of the Service;
                    to market products and services that we believe may be of interest to you (you may opt out of this);
                    to fulfill your requests for certain products and services;
                    for market research, project planning, troubleshooting problems, detecting and protecting against error, fraud or other criminal activity;
                    to de-identify your personal information and create aggregated data, as discussed below;
                    to enforce our Terms of Use; and
                    as otherwise set forth in this Privacy Policy (including to protect our rights, if required by law, or relating to a transfer of control or sale of assets, as discussed below).
                    

                    <br/><strong>How and Why We Share Your Personal and Other Information</strong><br/>

                    Guac and its affiliated companies use third-party service providers to help us manage and improve the Service. These service providers may collect and/or use your personal or other information to assist us in achieving the purposes discussed above in the section entitled “How We Use Your Personal Information.” For example, we use third parties to host features of our Service and to help us target and implement our email communications and direct mailings. We also use third-party platforms to help us manage our relationships with the people and companies that use our services. And we use third parties to assist us with market research, troubleshooting problems, and detecting and protecting against error, fraud or other criminal activity. We may also transmit your personal information to our banking partners in order to meet legal requirements.
                    We may also share your personal information with other third parties when necessary to fulfill your requests for services; to complete a transaction that you initiate; to meet the terms of any greement that you have with us or our partners; or to manage our business.
                    In particular, we may share certain information with financial institutions and our other partners to provide the Service features that you elect to use. For example, if you provide your online login information for financial accounts to us, we may disclose that to your financial institution or its intermediary in order to establish a connection between your financial account and your Guac account, and thereby obtain access to your financial records. Similarly, if you link your Guac account to other services we may share your personal information in order to provide the Service features you requested. Such as applying for a financial product or service, we will disclose any personal information that the financial institution requires to complete the transaction in question.
                    The Service contains comment fields and other features that permit you to share information publicly. The Service also may enable you to share personal information with websites or online services operated by third parties. For example, the Site contains links and widgets for third-party websites that incorporate comment and social media features. If you choose to use these features, you may disclose your personal information not just to those third-party websites and services, but also to their users and the public more generally. Because these third-party websites and services are not operated by Guac, we are not responsible for the content or practices of those websites or services. The collection, use, and disclosure of your personal and non-personal information will be subject to the privacy policies of the third party websites or services, and not this Privacy Policy.
                    The Service also integrates certain third-party plug-ins (such as a Facebook “like” button). Even if you do not click on these plug-ins, they may collect information about you, such as your IP address and the pages that you view. They also may set and/or access a cookie. These plugins are governed by the privacy policy of the company providing them. Our practices with respect to cookies and other tracking mechanisms is discussed below.
                    Guac reserves the right (and you authorize Guac) to share or disclose your personal information when Guac determines, in its sole discretion, that the disclosure of such information is necessary or appropriate:

                    Legal purposes. We also may use or share your personal or non-personal information with third parties when we believe, in our sole discretion, that doing so is necessary:
                    To comply with applicable law or a court order, subpoena, or other legal process;
                    To investigate, prevent, or take action regarding illegal activities, suspected fraud, violations of our terms and conditions, or situations involving threats to our property or the property or physical safety of any person or third party;
                    To establish, protect, or exercise our legal rights or defend against legal claims; or
                    To facilitate the financing, securitization, insuring, sale, assignment, bankruptcy, or other disposal of all or part of our business or assets.
                    

                    Finally, your personal information may be transferred to a third party as a result of a sale, acquisition, merger, reorganization or other change of control, reorganization, bankruptcy, or asset sale. If we sell, merge or transfer any part of our business, part of the sale may include your personal information.

                    <br/><strong>Restrictions and Monitoring of Our Partners</strong><br/>
                    Your use of the Guac Platform confirms acceptance of Plaid’s Privacy Policy (3rd party provider, see https://www.plaid.com/legal/#privacy-policy). You expressly authorize and grant to Plaid the right, power and authority to act on your behalf to access and transmit your data as reasonably necessary for Plaid to provide service to you.

                    Your Online Activity: How We Use Cookies
                    Online Session Information and Use is Collected to Improve Your Experience We may also collect technical and navigational information, such as computer browser type, Internet protocol
                    address, pages visited, and average time spent on our Site. This information may be used, for example, to alert you to software compatibility issues, or it may be analyzed to improve our design and functionality and service.

                    We Use Cookies to Improve Your Experience

                    “Cookies” are alphanumeric identifiers in the form of text files that are inserted and stored by your Web browser on your computer’s hard drive. Guac may set and access cookies on your computer to track, gather and store preferential information about you. We encode our cookies to prevent others with whom we do not have a relationship with from being able to interpret the information stored in them. Note that most Internet browsers will allow you to stop cookies from being stored on your computer and to delete cookies stored on your computer. If you choose to eliminate cookies, the full functionality of the Service may be impaired for you.

                    <br/><strong>We May Use Third Party Providers to Help Us Improve Our Service and to Target Online Advertising</strong><br/>
                    We may also use third party service providers to help us analyze certain online activities. For example, these service providers may help us measure the performance of our online campaigns or analyze visitor activity. We may permit these service providers to use cookies and other technologies to perform these services for us. We do not share any personal information about our customers with these third party service providers, and these service providers do not collect such information on our behalf.
                    The Service also enables third-party tracking mechanisms to collect your non-personal information for use in online behavioral advertising. For example, third parties may use the fact that you visited the Website to target online ads for Guac services to you on non-Guac websites. In addition, our third-party advertising networks might use information about your use of the Service to help target non-Guacl advertisements based on your online behavior in general. For information about behavioral advertising practices, including privacy and confidentiality, visit the Network Advertising Initiative site online
                    The use of online tracking mechanisms by third parties is subject to those third parties’ own privacy policies, and not this Privacy Policy. If you prefer to prevent third parties from setting and accessing cookies on your computer, you may set your browser to block cookies. Additionally, you may remove yourself from the targeted advertising of companies within the Network Advertising Initiative by opting out, or of companies participating in the Digital Advertising Alliance program by opting out here. Although our website currently does not respond to “do not track”
                    browser headers, you can limit tracking through these third-party programs and by taking the other steps discussed above.

                    <br/><strong>How We Use Anonymized Data: Peer Comparisons, Research & Development</strong><br/>
                    Guac may make anonymous or aggregate personal information and disclose such data only in a non-personally
                    identifiable manner to:

                    Organizations approved by Guac that conduct research into consumer spending; and
                    Users of the Service for purposes of comparison of their personal financial situation relative to the broader community.
                    

                    Such information does not identify you individually.
                    ©2020 New World Savings. All Rights Reserved
                    

                    </p>
                </div>
                </div>
            </div>
        </div>

     


    )
}