/* This example requires Tailwind CSS v3.0+ */
import { useState } from 'react'
import { Dialog, Transition } from '@headlessui/react';
import { NavigationModel } from "../shared/layout/header/model"
import { IconPath } from '../../common/icon';
import React from 'react'

export interface HomeProps {
}

export const GoalsTemplate: React.FC<HomeProps> = (props: HomeProps) => {

    return (
        <>
        <div data-name='goal' id="goal" className='xl:max-w-7xl mx-auto  flex xl:h-screen xsm:px-8 lg:px-0 items-center lg:flex-row xsm:flex-col'>
            <div className="relative px-12 lg:px-12 xl:w-1/2 lg:w-full md:flex-col" >
                <div className="mx-auto max-w-2xl py-12 sm:py-4 lg:py-4">
                <div className="hidden sm:mb-8 sm:flex sm:justify-center">
                </div>
                <div className="text-left ">
                    <label htmlFor="easytouse " className='text-primary'>Easy to use </label>
                    <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                    Guac helps you save money faster
                    </h1>
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                    Our auto-tip technology and percentage-based savings are proven to save you more money than roundup apps.
                    </p>
                    <div className="mt-10 flex items-center justify-start gap-x-6">
                    <a href="https://app.guacapp.com/" className="text-base font-semibold leading-7 bg-primary px-8 py-4 rounded-md text-white">
                        Get Started <span aria-hidden="true">→</span>
                    </a>
                    </div>
                </div>
                </div>
            </div>
            <div className=" xsm:mx-auto xsm:w-full  xl:w-1/2">
                <img src={require('../../assets/goals.png')} alt="" />
            </div>
            </div>
            
            
            
            {/* <div className="flex xl:max-w-7xl mx-auto  xl:flex-row relative px-6 lg:px-20 bg-primary xsm:flex-col lg:flex-row md:w-full">
                <div className="lg:w-1/2 xsm:my-0 lg:my-40 xsm:w-full">
                    <div className="hidden sm:mb-8 sm:flex sm:justify-center"></div>
                    <div className=" lg:w-full flex-col justify-center items-center text-white xsm:w-full">
                    <h1 className="text-left text-4xl font-bold tracking-tight sm:text-6xl">
                        Save & Earn across all devices
                    </h1>
                    <p className="text-left mt-6 text-lg leading-8">
                        With both Mobile & Web Apps, Guac gives you unlimited access to Savings Tools & the Marketplace any time, any place.
                    </p>


                    <div className='flex pt-12 left relative text-primary md:flex-col'>
                        <ul className='flex w-full sm:justify-evenly md:justify-items-start xsm:flex-col md:flex-row'>
                                <li className='bg-white px-4 py-1 rounded-md flex-row xsm:mb-3 md:mr-4'>
                                    <a className = 'h-[40px] flex items-center text-sm xsm:justify-center md:justify-items-start' href="">
                                    <img className='w-[40px]' src={require('../../assets/apple.svg').default} alt="" />
                                    <div className='pl-1'>
                                        <label className='text-[11px]' htmlFor="appstore">GET IT ON</label>
                                        <h3 className='font-bold'>App Store</h3>
                                    </div>
                                    </a>
                                </li>
                                <li className='bg-white px-4 py-1 rounded-md flex-row xsm:mb-3 lg:flex-col md:mr-4' >
                                    <a className = 'h-[40px] flex items-center xsm:justify-center md:justify-items-start text-sm' href="">
                                    <img className='w-[40px]' src={require('../../assets/googleplay.svg').default} alt="" />
                                    <div className='pl-1'>
                                        <label className='text-[11px]' htmlFor="appstore">GET IT ON</label>
                                        <h3 className='font-bold'>Google Play</h3>
                                    </div>
                                    </a>
                                </li>

                                <li className='bg-white px-4 py-1 rounded-md flex-row xsm:mb-3 lg:flex-col '>
                                    <a className = 'h-[40px] flex items-center xsm:justify-center md:justify-items-start text-sm' href="">
                                    <img className='w-[40px]' src={require('../../assets/desktop.svg').default} alt="" />
                                    <div className='pl-1'>
                                        <label className='text-[11px]' htmlFor="appstore">Access</label>
                                        <h3 className='font-bold'>Web App</h3>
                                    </div>
                                    </a>
                                </li>

                            </ul>
                        </div>
                    
                    </div>
                </div>
                <div className="xl:w-1/2 lg:my-40 xsm:w-full md:my-0">
                    <img className='mx-auto'
                    src={require('../../assets/goalsimg.png')}
                    alt=""
                    />
                </div>
                
            </div> */}


        </>

    )
}