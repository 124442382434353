import { useState } from 'react';
import { Disclosure } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/24/solid"
import faqData from "./resource.json"


type FAQTemplateProps = {
    faqs: Array<any>
}

export const FaqSection: React.FC<FAQTemplateProps> = (props: FAQTemplateProps) => {
    const { faqs } = props;
        return (
            <>
            <div className="bg-white">
                <div className="mx-auto max-w-full py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
                    <div className="mx-auto max-w-7xl divide-y-2 divide-gray-200">
                        <h2 className="text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                            Frequently asked questions
                        </h2>
                        <dl className="mt-6 space-y-6 divide-y divide-gray-200">
                            {faqData.faqContent.map((faq) => (
                                <Disclosure as="div" key={faq.title} className="pt-6">
                                    {({ open }) => (
                                        <>
                                            <dt className="text-lg">
                                                <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
                                                    <span className="font-medium text-gray-900">{faq.title}</span>
                                                    <span className="ml-6 flex h-7 items-center">
                                                        <ChevronDownIcon
                                                            className={`${open ? '-rotate-180' : 'rotate-0'}  h-6 w-6 transform`}
                                                            aria-hidden="true"
                                                        />
                                                    </span>
                                                </Disclosure.Button>
                                            </dt>
                                            <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                                <p className="text-base text-gray-500">{faq.answer}</p>
                                            </Disclosure.Panel>
                                            <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                                <video className="w-1/4" muted controls>
                                                    <source src={faq.videoURL} type="video/mp4"/>
                                                </video>
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>
                            ))}
                        </dl>

                    <div className="div2 mt-40 w-full lg:w-full max-w-full md:ml-2 mx-auto max-w-2xl text-left">
                        <p className=" text-md leading-8 text-gray-600 font-bold xsm:mt-10 lg:mt-0">
                        Contact Info
                        </p>
                        <ul className="mt-6 text-sm">
                        {/* <li className="mb-4">
                            33831 Granada Drive, Dana Point, CA 92629
                        </li> */}
                        <li className="mb-4 flex items-center">
                            <img src={require('../../assets/footerphone.svg').default} alt="" className="mr-4 max-w-[30px]" />+1 (949) 992-5707
                        </li>
                        <li className="mb-4 flex items-center">
                            <img src={require('../../assets/footermail.svg').default} alt="" className="mr-4 max-w-[30px]" />Support@guacapp.com
                        </li>
                        </ul>
                    </div>


                    </div>

                
                </div>
            </div>
        </>
        )
    }
