/* This example requires Tailwind CSS v3.0+ */
import { useState } from 'react'
import { Dialog, Transition } from '@headlessui/react';
import { NavigationModel } from "../shared/layout/header/model"
import { IconPath } from '../../common/icon';
export interface HomeProps {
}

export const HomeTemplate: React.FC<HomeProps> = (props: HomeProps) => {

    return (
        <>
        <div id='home' 
        
        className="xl:max-w-7xl mx-auto flex flex-col lg:flex-row  relative px-6 lg:px-8 bg-no-repeat bg-background-frame">
            
            <div className="w-full lg:w-1/2 my-8 lg:my-40 md:w-full">
                <div className="hidden sm:mb-8 sm:flex sm:justify-center"></div>
                <div className="w-3/5 px-4 lg:px-12 flex-col justify-center items-center text-center xsm:w-full xsm:mt-20">
                    <h1 className="text-left text-5xl lg:text-5xl font-bold tracking-tight text-gray-900 xsm:text-4xl">
                        What if saving was as easy as spending?{' '}
                    </h1>
                <p className="text-left mt-4 lg:mt-6 text-base lg:text-lg leading-8 text-gray-600">
                Guac is designed to help you automatically save toward your goals.
                </p>
                <div className="justify-start mt-6 lg:mt-10 flex items-left  justify-left gap-x-6">
                    <a href="https://app.guacapp.com/" className="bg-primary rounded-md  px-6 lg:px-8 py-3 lg:py-4 text-white font-semibold leading-7">
                    Try now for free <span aria-hidden="true"></span>
                    </a>
                </div>
                </div>
            </div>
                <div className="w-full lg:w-1/2 my-8 lg:my-40">
                    <img src={require('../../assets/homepageImg.svg').default} alt="" className="w-full" />
                    
                </div>
            </div>
            <div className=" xl:max-w-7xl mx-auto  block relative bg-primary">
                    <ul className="w-full  mx-auto px-4 lg:px-8 py-6 lg:py-8 justify-start lg:justify-evenly flex items-center flex-wrap md:justify-center sm:justify-center">
                        <li className="px-2 lg:px-8 w-1/3 text-center mb-3 lg:w-1/6 xsm:max-w-[90px] lg:max-w-[150px]">
                        <img src={require('../../assets/amazonlogo.svg').default}  alt="" className="lg:w-full xsm:w-[130px] lg:border-b-0 " />
                        </li>
                        <li className="px-2 lg:px-8 w-1/3 text-center mb-3  lg:w-1/6 xsm:max-w-[90px] lg:max-w-[150px]">
                        <img src={require('../../assets/asalogo.svg').default}  alt="" className="lg:w-full xsm:w-[130px] lg:border-b-0  xsm:mb-1" />
                        </li>
                        <li className="px-2 lg:px-8 w-1/3 text-center mb-3  lg:w-1/6 xsm:max-w-[90px] lg:max-w-[150px]">
                        <img src={require('../../assets/Google.svg').default}  alt="" className="lg:w-full xsm:w-[130px] lg:border-b-0  xsm:mb-1" />
                        </li>
                        <li className="px-2 lg:px-8 w-1/3 text-center mb-3  lg:w-1/6 xsm:max-w-[90px] lg:max-w-[150px]">
                        <img src={require('../../assets/o2logo.svg').default}  alt="" className="lg:w-full xsm:w-[130px]  lg:border-b-0  xsm:mb-1" />
                        </li>
                        <li className="px-2 lg:px-8 w-1/3 text-center mb-3  lg:w-1/6 xsm:max-w-[90px] lg:max-w-[150px]">
                        <img src={require('../../assets/Walmart.svg').default}  alt="" className="lg:w-full xsm:w-[130px] lg:border-b-0  xsm:mb-1" />
                        </li>
                    </ul>
            </div>
    </>
    )
}