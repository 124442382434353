import React from 'react';

import { CheckIcon } from '@heroicons/react/24/outline'

const Pricing = () => {
  return (
      <>
    <div id="pricing" data-name='pricing' className='xl:max-w-7xl mx-auto  w-full text-white mb-4'>
      {/* <div className='lg:max-w-7xl xsm:w-full h-[600px] bg-[#3AB100] absolute '></div> */}

            {/* <div className='max-w-[1000px] mx-auto py-12'>

                    <div className='text-center py-8 text-white mix-blend-overlay'>
                    <h2 className='text-3xl uppercase'>Simple Plans for Everyone</h2>
                    <p className='text-l'>
                        No contracts. No surprise fees
                    </p>
                    </div>

                    <div className='grid md:grid-cols-2'>

                    <div className='bg-white text-slate-900 m-4 p-8 rounded-xl shadow-2xl relative'>
                            <span className='uppercase px-3 py-1 bg-[#3AB100] text-white rounded-2xl text-sm'>Starter</span>
                            <div>
                                <p className='text-6xl font-bold py-4 flex'>$0<span className='text-xl text-slate-500 flex flex-col justify-end'>/mo</span></p>
                            </div>
                            <p className='text-2xl py-8 text-slate-500'>Lorem ipsum dolor, sit amet consectetur adipisicing.</p>
                            <div className='text-2xl'>
                                <p className='flex py-4'><CheckIcon className='w-8 mr-5 text-green-600'  />Daily withdrow limit.</p>
                                <p className='flex py-4'><CheckIcon className='w-8 mr-5 text-green-600'  />Daily withdrow limit</p>
                                <p className='flex py-4'><CheckIcon className='w-8 mr-5 text-green-600'  />Lorem, ipsum dolor.</p>
                                <button className='bg-[#3AB100] px-8 py-4 rounded-md text-white w-full py-4 my-4'>Continue with Free Basic Plan</button>
                            </div>
                    </div>
                    <div className='bg-white text-slate-900 m-4 p-8 rounded-xl shadow-2xl relative'>
                            <span className='uppercase px-3 py-1 text-white bg-[#3AB100] rounded-2xl text-sm'>Pro Free Trial</span>
                            <div>
                            <p className='text-6xl font-bold py-4 flex'>$9.99<span className='text-xl text-slate-500 flex flex-col justify-end'>/mo</span></p>
                            </div>
                            <p className='text-2xl py-8 text-slate-500'>Lorem ipsum dolor, sit amet consectetur adipisicing.</p>
                            <div className='text-2xl'>
                                <p className='flex py-4'><CheckIcon className='w-8 mr-5 text-green-600'  />Rapid Money Withdrow</p>
                                <p className='flex py-4'><CheckIcon className='w-8 mr-5 text-green-600'  />Rapid Money Withdrow</p>
                                <p className='flex py-4'><CheckIcon className='w-8 mr-5 text-green-600'  />Rapid Money Withdrow</p>
                                <button className='bg-[#3AB100] px-8 py-4 rounded-md text-white w-full py-4 my-4'>Start your free trial now</button>
                            </div>
                    </div>
                    </div>
            </div> */}
    </div>


    {/* <div className='xl:max-w-7xl mx-auto relative px-6 lg:px-8 text-center bg-[#3AB100] h-60vh flex  items-center h-full py-12 mb-8 lg:flex-col md:flex-col xsm:w-full xsm:flex-col'>
        <div className='flex px-6 pl-12 left w-1/2 relative lg:w-full xsm:w-full' >
            <img className='max-w-[200px]' src={require('../../assets/Guac_Horizontal_Logo.png')}  alt="" />
            <div className='flex text-left flex-col px-6  text-white'>
                <h3 className=' text-2xl font-bold'>
                Download Guac App
                </h3>
                <p>
                Get you 1 month free trial by downloading the Guac App now. Start Saving now!
                </p>
            </div>
        </div>

        <div className='flex pt-12 left relative text-[#3AB100] lg:w-full lg:px-6 md:flex-col'>
            <ul className='flex xsm:flex-col md:flex-row '>
                    <li className='bg-white px-6 py-1 rounded-md xsm:flex-col md:flex-row md:mr-4 xsm:mb-3'>
                        <a className = 'h-[40px] flex items-center text-sm' href="">
                        <img className='w-[40px]' src={require('../../assets/apple.svg').default} alt="" />
                        <div className='pl-1'>
                            <label className='text-[11px]' htmlFor="appstore">GET IT ON</label>
                            <h3 className='font-bold'>App Store</h3>
                        </div>
                        </a>
                    </li>
                    <li className='bg-white px-6 py-1 rounded-md xsm:flex-col md:flex-row md:mr-4 xsm:mb-3' >
                        <a className = 'h-[40px] flex items-center text-sm' href="">
                        <img className='w-[40px]' src={require('../../assets/googleplay.svg').default} alt="" />
                        <div className='pl-1'>
                            <label className='text-[11px]' htmlFor="appstore">GET IT ON</label>
                            <h3 className='font-bold'>Google Play</h3>
                        </div>
                        </a>
                    </li>

                    <li className='bg-white px-6 py-1 rounded-md xsm:flex-col md:flex-row md:mr-4 xsm:mb-3'>
                        <a className = 'h-[40px] flex items-center text-sm' href="">
                        <img className='w-[40px]' src={require('../../assets/desktop.svg').default} alt="" />
                        <div className='pl-1'>
                            <label className='text-[11px]' htmlFor="appstore">Access</label>
                            <h3 className='font-bold'>Web App</h3>
                        </div>
                        </a>
                    </li>

                </ul>
            </div>


    </div> */}
    </>
  );
};

export default Pricing;
